/*
|==========================================================================
| jQuery TablePress
|==========================================================================
| 
| This function cleans up the default TablePress implementation if using
| the TablePress plugin, adding accessibility and a responsive layout.
|
|==========================================================================
|
*/

function tablepress() {

	// Table Clean Up
	jQuery('.tablepress').attr('summary','Comparison Table');
	jQuery('.tablepress').attr('aria-readonly','true');
	jQuery('.tablepress th').attr('role','columnheader');
	jQuery('.tablepress td').attr('role','gridcell');

	jQuery("th").each(function(i){
		if(jQuery(this).is(':empty') || jQuery(this).html() === '&nbsp;'){
			jQuery(this).html('<span class="show-for-sr">Account Description Row</span>');
		}
	});

	jQuery('.aria-hidden-true').attr('aria-hidden','true');

	// Create Accessible Content Container for Table Lists
	jQuery('<div class="show-for-sr tablepress-account-comparison-table-accessible"><h3>Account Types</h3></div>').insertBefore('.tablepress-account-comparison-table');

	// Get Account Types
	var account_titles = jQuery('.tablepress-account-comparison-table th:not(.column-1)').each(function(i){
		var this_text = jQuery.trim(jQuery(this).text());
		var this_class = jQuery(this).attr('class');
		if(this_text != '') { 
			var column_Object = new Object();
			column_Object.name = this_text;
			column_Object.class = this_class;

			// Parse Account Attributes
			var account_attributes = [];
			jQuery('.tablepress-account-comparison-table td.'+this_class).each(function(i){
				var column_label = jQuery.trim(jQuery(this).parent().find('.column-1').text());
				var column_text = jQuery.trim(jQuery(this).html());
				if(column_label === '') {
					column_label = 'Account Description';
				}
				var column_string = '<span class="field-label">'+column_label + ':</span> ' + column_text; 
				var column_class = jQuery(this).attr('class');
				if(column_text != '') { 
					account_attributes.push( column_string );
				}
			});

			column_Object.values = account_attributes;

			var account_html;
			account_html += "<ul>";
			account_html += '<li>' + column_Object.name + '</li>';
			jQuery.each(column_Object.values, function (index, item) {
				account_html += "<li>" + item + "</li>";
			});
			account_html += "</ul>";
			var account_html_clean = account_html.replace("undefined", "");
			jQuery(".tablepress-account-comparison-table-accessible").append(account_html_clean);
		}
	});

	table_appearance_rendering(table_parent_class);
	table_appearance_hover(table_parent_class);

};

function tableacf() {

	jQuery('.content-blocks .table-row.comparison_table table tbody td:first-child').each(function(i){

		var account_text = jQuery(this).text();
		var account = account_text.toLowerCase().replace(/(?:\r\n|\r|\n)/g, '-').replace(' ','-').replace(' ','-').replace(/([^a-z])/, '-').replace(/\s+/, " ").replace(/\s/, '-');

		if(jQuery(".table-row.comparison_table").length > 0) {
			// check how many cols table as
			var cols = document.getElementById("comparison-table").rows[0].cells.length;
	
			if(cols > 6) {
				jQuery(this).append('<button type="button" class="row-link internal flex flex-wrap justify-start items-center mt3 sans tl" data-open="comparison-account-offcanvas" data-account="'+ account +'">Account details <span class="icon-arrow-right mt2"></span></button>');
			} else {
				jQuery(this).append('<button type="button" class="row-link internal flex justify-center items-center mt3 sans tl" data-open="comparison-account-offcanvas" data-account="'+ account +'">Account details <span class="icon-arrow-right ml2"></span></button>');
			}
	
		}

	});

	
	jQuery('.content-blocks .table-row.comparison_table button').on('click', function(e) {
		var button_clicked = jQuery(this).attr('data-account');
		console.log(button_clicked);
		jQuery('.accounts_container .account').addClass('hide');
		jQuery('.accounts_container .account[data-account="'+button_clicked+'"]').removeClass('hide');
	} );

	jQuery('.data-header li').each(function(i){
		var data_header = jQuery(this).clone().attr('data-header');
		var data_header_id = jQuery(this).clone().attr('id-header');
		var data_list_id = jQuery(this).parent().parent().parent().find(".data-list li[id-list='"+data_header_id+"'] span");
		//console.log(data_list_id);
		data_list_id.html(data_header);
	});

	if(jQuery(".table-row.thead-is-sticky").length > 0) {
		jQuery('body').addClass('is-table-freeze');
	}

	// For comparison table, change breakpoint base on numbers of tables cols
	if(jQuery(".table-row.comparison_table").length > 0) {
		// check how many cols table as
		var cols = document.getElementById("comparison-table").rows[0].cells.length;

		if(cols > 6) {
			jQuery('.table-row.comparison_table .sticky-thead table').removeClass('show-for-medium_to_large').addClass('show-for-xlarge');
			jQuery('.table-row.comparison_table table.no-sticky').removeClass('show-for-medium_to_large').addClass('show-for-xlarge');
			jQuery('.table-row.comparison_table .table-mobile').removeClass('hide-for-medium_to_large').addClass('hide-for-xlarge');
		}

	}


	tablefixedcol();
	

	jQuery(window).on('resize', function() {
        tablefixedcol();
    });

};


function tablefixedcol() {

	if(jQuery(".table-row.scrollable_table").length > 0 && !jQuery("body.is-ie").length > 0) {

		// thead height
		var thead_height = jQuery('.content-blocks .table-row.scrollable_table table thead').height();
			
		//console.log(thead_height);

		jQuery('.content-blocks .table-row.scrollable_table table thead th:first-child').css('height',thead_height);

		setTimeout(function(){

			jQuery('.content-blocks .table-row.scrollable_table table tbody td:first-child').each(function(i){
			
				var tbody_height = jQuery(this).parent().height();
	
				jQuery(this).css('height',tbody_height);
	
			});

		},500);
		
	}
	
}