/*
|==========================================================================
| Initialize Foundation
|==========================================================================
| 
| This initializes Foundation.
|
|==========================================================================
|
*/

function randomID() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}

// Off Canvas Duplicate ID Cleanup
jQuery('.off-canvas .menu-item').each(function(i){
    var this_element = jQuery(this);
    var this_element_id = this_element.attr('id');
    if(!this_element_id){
        this_element_id = randomID();
    }
    this_element.attr('id',this_element_id+'-off-canvas');
});

// Small Nav Duplicate ID Cleanup
jQuery('.top-bar-right-small-nav a').each(function(i){
    var this_element = jQuery(this);
    this_element_id = randomID();
    this_element.attr('id',this_element_id+'-small-nav');
});

// Duplicate Comparison Chart ID Cleanup
jQuery("section.block-layout-table").each(function(i){
    var this_element = jQuery(this);
    var this_id = this_element.attr('id');
    var ids = document.querySelectorAll("#"+this_id);  
    if(ids.length > 1 && ids[0] === this) {
        console.warn('Multiple IDs #'+this_id);
    }
    if(ids.length > 1 && ids[0] !== this) {
        var newID = randomID();
        this.id = newID;
        console.warn('ID Changed to '+newID);
    }
});

// Foundation
jQuery(document).foundation();

// Mega Menu Connection
// Important for Foundation Dropdown Pane Label Accessibility Correction
jQuery('#mega-menu > .dropdown-pane').each(function(i){
    var this_element = jQuery(this);
    var aria_labelledby = this_element.data('menu-item-id');
    this_element.attr('aria-labelledby',aria_labelledby); 
});

// Search Aria Cleanup
jQuery('#searchdropdown').attr('aria-labelledby','search-toggle');